<template>
  <div class="page-cu-container">
    <div class="page-cu-top" ref="searchbox">
      <a-form layout="inline">
        <a-form-item>
          <a-input v-model="page.key" placeholder="请输入关键字"></a-input>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.sex"
            placeholder="请选择性别"
            style="width: 150px"
          >
            <a-select-option value="0">未知</a-select-option>
            <a-select-option value="男">男</a-select-option>
            <a-select-option value="女">女</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-range-picker
            :placeholder="['注册开始时间', '注册结束时间']"
            style="width: 400px !important"
            show-time
            format="YYYY-MM-DD HH:mm:ss"
            @change="getTime"
          ></a-range-picker>
        </a-form-item>
        <a-form-item>
          <a-select
            v-model="page.state"
            placeholder="请选择状态"
            style="width: 150px"
          >
            <a-select-option value="1">启用</a-select-option>
            <a-select-option value="2">禁用</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="searchList" icon="search"
            >搜索</a-button
          >
        </a-form-item>
        <a-form-item>
          <a-button
            :icon="getExicon.icon"
            :disabled="getExicon.ed"
            @click="$exportFun('/export/user', page, 'userManager')"
            >导出</a-button
          >
        </a-form-item>
        <!-- <a-form-item>
          <a-button type="default" @click="addAction" icon="plus-circle"
            >新增</a-button
          >
        </a-form-item> -->
      </a-form>
    </div>
    <div class="page-cu-main">
      <a-table
        :columns="columns"
        :data-source="list"
        rowKey="id"
        bordered
        :pagination="false"
        :scroll="{ y: tableHeigt }"
        :show-total="(total, range) => `总数：${total} 条`"
      >
        <template slot="state" slot-scope="text">
          <a-tag color="green" v-if="text == '1'">启用</a-tag>
          <a-tag color="orange" v-if="text == '2'">禁用</a-tag>
        </template>
        <template slot="sex" slot-scope="text">
          <span v-if="text == '0'">未知</span>
          <span v-else-if="text == '1'">男</span>
          <span v-else-if="text == '2'">女</span>
          <span v-else>{{ text }}</span>
        </template>
        <template slot="idno_positive_img_url" slot-scope="text">
         <view-file :data="text"></view-file>
        </template>
        <template slot="bank_card" slot-scope="text,record">
          <div v-if="record.bank_card != null && record.bank_card != ''">{{record.bank_provinces}},{{record.bank_name}},{{record.bank_card}}</div>
          <div v-else>暂无信息</div>
        </template>
        
        <template slot="action" slot-scope="text, record">
          <!-- <a-button
              type="link"
              size="small"
              icon="delete"
              @click="() => delConfirm(record['id'])"
              >删除</a-button
            > -->
          <a-button
            type="link"
            size="small"
            icon="unlock"
            @click="() => resetPassword(record['id'])"
            >重置密码</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="minus-circle"
            v-if="record['state'] == '1'"
            @click="() => enabledState(record['id'], 2, '你确定要禁用吗？')"
            >禁用</a-button
          >
          <a-button
            type="link"
            size="small"
            icon="check-circle"
            v-if="record['state'] == '2'"
            @click="enabledState(record['id'], 1, '你确定要启用吗？')"
            >启用</a-button
          >
        </template>
      </a-table>
      <div class="page-cu-pagination">
        <a-pagination
          :show-total="(total, range) => `总数：${total} 条`"
          :page-size.sync="page.pageSize"
          :total="page.totalRow"
          v-model="page.start"
          @change="changePage"
        />
      </div>
    </div>
    <a-modal
      v-model="imgvisible"
      title="预览"
      ok-text="确认"
      @ok="hideModal"
      :footer="null"
    >
      <img
        style="width: 100%"
        :src="viewImgData + '?x-oss-process=image/resize,m_fixed,h_400,w_400'"
        alt=""
      />
    </a-modal>
  </div>
</template>

<script>
import * as Api from "./api";
export default {
  name: "index",
  data() {
    return {
      tableHeigt: 0,
      imgvisible: false,
      viewImgData: "",
      isAdd: true, // TODO: 是否是新增操作
      columns: [
        {
          title: "序号",
          align: "center",
          width: 70,
          customRender: (text, record, index) => `${index + 1}`,
        },
        {
          title: "姓名",
          dataIndex: "name",
          width: 120,
        },
        // {
        //   title: "性别",
        //   dataIndex: "sex",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "年龄",
        //   dataIndex: "age",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "民族",
        //   dataIndex: "national",
        //   width: 70,
        //   align: "center",
        // },
        // {
        //   title: "籍贯",
        //   dataIndex: "census_register_seat",
        //   width: 270,
        // },
        // {
        //   title: "手机号",
        //   dataIndex: "mobile",
        //   width: 140,
        // },
        // {
        //   title: "身份证号",
        //   dataIndex: "idno",
        //   width: 200,
        // },
        {
          title: "身份证正面",
          dataIndex: "idno_positive_img_url",
          key: "idno_positive_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          align: "center",
          width: 120,
        },
        {
          title: "身份证反面",
          dataIndex: "idno_reverse_img_url",
          key: "idno_reverse_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          align: "center",
          width: 120,
        },
        {
          title: "生活照",
          dataIndex: "head_img_url",
          key: "head_img_url",
          scopedSlots: { customRender: "idno_positive_img_url" },
          align: "center",
          width: 120,
        },
        // {
        //   title: "应急联系人",
        //   dataIndex: "contact_name",
        //   width: 120,
        // },
        // {
        //   title: "应急联系人手机号码",
        //   dataIndex: "contact_mobile",
        //   width: 160,
        // },

        {
          title: "状态",
          dataIndex: "state",
          scopedSlots: { customRender: "state" },
          align: "center",
          width: 70,
        },

        // {
        //   title: "备注",
        //   dataIndex: "remark",
        //   width: 120,
        // },
        {
          title: "身份证号",
          dataIndex: "idno",
        },
        {
          title: "民族",
          dataIndex: "national",
          align: "center",
          width: 70,
        },
        {
          title: "银行卡信息",
          dataIndex: "bank_card",
          scopedSlots: { customRender: "bank_card" },
        },
        {
          title: "注册时间",
          dataIndex: "create_time",
        },
        {
          title: "操作",
          scopedSlots: { customRender: "action" },
          align: "center",
        },
      ],
      page: {
        start: 1,
        totalRow: 0,
        pageSize: 0,
        limit: 20,
        key: "",
        sex: undefined,
        state: undefined,
      },
      list: [],
      actionVisible: false,
      record: "",
      menuList: [],
      showTree: false,
      expandedKeys: [],
      autoExpandParent: true,
      treeFields: {
        children: "children",
        title: "name",
        key: "id",
      },
      selectedIds: [],
      selectHalf: [],
      // 解决数据树父子节点选中的问题
      checkedKeys: { checked: [], halfChecked: [] },
      jobJson: [], // 工种下拉数据
    };
  },
  computed: {
    getExicon() {
      return this.$store.state.EXICON;
    },
  },
  mounted() {
    this.getList();
    this.$nextTick(function () {
      this.tableHeigt =
        document.body.clientHeight - this.$refs.searchbox.offsetHeight - 170;
    });
  },
  methods: {
    // 删除确认
    delConfirm(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要删除吗?",
        onOk() {
          that.del(id);
        },
        onCancel() {},
      });
    },

    // 重置密码确认
    async resetPassword(id) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "danger",
        okText: "确定",
        cancelText: "取消",
        content: "您确定要重置密码吗?",
        onOk() {
          that.resetPwdData(id);
        },
        onCancel() {},
      });
    },

    async resetPwdData(id) {
      let res = await Api.resetPwd({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },

    // 删除
    async del(id) {
      let res = await Api.Delete({ id: id });
      if (res && res.code == "0") {
        this.$message.success(res.message);
        this.getList();
      } else {
        this.$message.error(res.message);
      }
    },
    // 列表
    async getList() {
      let res = await Api.List({ ...this.page });
      this.page.totalRow = res.page.totalRow;
      this.page.pageSize = res.page.pageSize;
      this.list = res.page.list;
    },

    changePage(currentPage) {
      this.page.start = currentPage;
      this.getList();
    },
    // 搜索
    searchList() {
      this.page.start = 1;
      this.getList();
    },
    // 启用 / 禁用
    enabledState(id, state, msg) {
      let that = this;
      this.$confirm({
        title: "信息提示",
        okType: "success",
        okText: "确定",
        cancelText: "取消",
        content: msg,
        async onOk() {
          let params = {
            id: id,
            state: state,
          };
          let res = await Api.AdminEnabled(params);
          if (res && res["code"] == "0") {
            that.getList();
            that.$message.success(res.message);
          } else {
            that.$message.error(res.message);
          }
        },
        onCancel() {},
      });
    },
    hideModal() {
      this.imgvisible = false;
    },
    getTime(t, v) {
      this.page.start_time = v[0];
      this.page.end_time = v[1];
    },
  },
};
</script>

<style scoped>
</style>


import request from '@/utils/request';

// 列表
export function List(params) {
    return request({
        url: '/admin/user/page',
        method: 'GET',
        params
    });
}

// 保存
export function Save(data) {
    return request({
        url: '/admin/user/save',
        method: 'POST',
        data
    });
}

// 更新
export function Update(data) {
    return request({
        url: '/admin/user/update',
        method: 'POST',
        data
    });
}

// 详情
export function Detail(params) {
    return request({
        url: '/admin/user/detail',
        method: 'GET',
        params
    });
}

// 删除
export function Delete(params) {
    return request({
        url: '/admin/user/delete',
        method: 'GET',
        params
    });
}
// 重置密码
export function resetPwd(params) {
    return request({
        url: '/admin/user/resetPwd',
        method: 'GET',
        params
    });
}

/**
 * 启用/禁用
 */
export function AdminEnabled(params) {
    return request({
        url: '/admin/user/changeIndex',
        method: 'GET',
        params
    });
}


